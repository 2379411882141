<template>
  <div v-if="selectedConfig" :style="{ backgroundColor: selectedConfig.color.concat('80')}">
    <div
      v-if="!selectedConfig.blocked && selectedConfig.allowCustomEvents"
      style="padding: 5px 0 0 5px;"
    >
          <v-btn 
            @click="view = 'schedule'"
            style="margin-right: 10px;"
            :color="view === 'schedule' ? selectedConfig.color : null"
            :dark="view === 'schedule' ? selectedConfig.dark : false"
            :large="view === 'schedule'"
          >
            <span 
              class="hidden-sm-and-down"
              :style="{color: view === 'schedule' ? selectedConfig.dark ? 'white' : 'black' : 'black'}"
            >Programación</span>

            <v-icon right>
              mdi-calendar-plus
            </v-icon>
          </v-btn>
          <v-btn 
            v-if="!selectedConfig.blocked && selectedConfig.allowCustomEvents"
            @click="view = 'manager'"
            :color="view === 'manager' ? selectedConfig.color : null"
            :dark="view === 'manager' ? selectedConfig.dark : false"
            :large="view === 'manager'"
            style="margin-right: 10px;"
          >
            <span 
              class="hidden-sm-and-down"
              :style="{color: view === 'manager' ? selectedConfig.dark ? 'white' : 'black' : 'black'}"
            >Crea tu evento propio</span>

            <v-icon right>
              mdi-ticket
            </v-icon>
          </v-btn>
    </div>
    <!--div
      v-if="selectedConfig.blocked"
      style="padding: 100px"
    >
      <center>
      <h1>No disponible</h1>
      </center>
    </div-->
    <schedule
      v-if="view === 'schedule'"
      :key="`${contentTypeID}-${contentType}`"
      :contentTypeID="contentTypeID"
      :planID="contentType"
      :locale="locale"
      :config="config.filter(x => x.id===contentType).shift()"
    />
    <custom-manager
      v-if="!selectedConfig.blocked && view === 'manager'"
      :key="`manager-${contentTypeID}-${contentType}`"
      :contentTypeID="contentTypeID"
      :planID="contentType"
      :locale="locale"
      :config="config"
      :selectedPlan="config.filter(x => x.id===contentType).shift()"
    />

    <!--div 
      v-else-if="view === 'manager'"
      style="padding: 100px; text-align: center;min-height: calc(100vh);"
    >
      <h1> TODO: pendiente de hacer </h1>
    </div-->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import Schedule from './Schedule'
import CustomManager from './customManager/Index'
export default {
  components: {
    Schedule,
    CustomManager,
  },
  data: () => ({
    view: null,
    config: null,
    selectedConfig: null,
    contentTypeID: null,
    contentType: null,
  }),
  computed: {
    ...mapState('app',['locale']),
  },
  watch: {
    '$route.params.id' () {
      this.init()
    },
    '$route.params.type' () {
      this.init()
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.view = 'schedule'
      this.contentTypeID = this.$route.params.id
      this.contentType = this.$route.params.type
      this.handleGetConfig()
    },
    handleGetConfig () {
      this.config = null
      this.selectedConfig = null
      api.getAll('dviewcontent', `v1/private/activity-barcelo-01/config/${this.contentTypeID}`)
        .then((response) => {
          this.config = response
          this.selectedConfig = this.config ? this.config.filter(x => x.id === this.contentType).shift() : null
        })
    },
  },
}
</script>

