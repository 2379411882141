<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
    >
      <v-card v-if="editedItem && dialog">
        <v-card-title class="text-h5">
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-if="config.subcategory && config.subcategory.length > 0"
              cols="12"
              sm="12"
              md="12"
              style="padding: 10px 20px;"
            >
                <v-btn 
                  v-for="subcategory of config.subcategory"
                  :key="subcategory.id"
                  @click="selectedSubcategory = subcategory.id"
                  :color="subcategory.id === selectedSubcategory ? 'primary' : null"
                  style="margin-right: 10px;"
                >
                  <span class="hidden-sm-and-down">{{ subcategory.name.es }}</span>
                </v-btn>
              <!--v-btn-toggle
                v-model="selectedSubcategory"
                borderless
                mandatory
              >
                <v-btn 
                  v-for="subcategory of config.subcategory"
                  :key="subcategory.id"
                  :value="subcategory.id"
                >
                  <span class="hidden-sm-and-down">{{ subcategory.name.es }}</span>
                </v-btn>
              </v-btn-toggle-->
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="padding: 10px 20px;"
            >
              <v-autocomplete
                item-value="ID"
                item-text="Name"
                v-model="editedItem.EventID"
                :items="allActivities.filter(x => (!x.SubcategoryID || !selectedSubcategory || x.SubcategoryID === selectedSubcategory) && (!x.WeekdayID || !editedItem.WeekdayID || x.WeekdayID === editedItem.WeekdayID))"
                outlined
                dense
                chips
                small-chips
                label="Activity"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <div style="background-color: #CCCCCC80;padding:  20px;border-radius:10px;">
                <interval-times
                  :label="`${$t('booking.scheduleForm.startTime')}:`"
                  v-model="editedItem.Time"
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <div style="background-color: #CCCCCC80;padding:  20px;border-radius:10px;">
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding: 0 0 0 170px;"
                >
                  <duration
                    :label="`${$t('booking.scheduleForm.duration')}:`"
                    v-model="editedItem.Duration"
                    :onChange="handleChangeDuration"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding: 5px 50px;font-style:italic;text-align:left;color:orange;"
                >
                    <small><b style="color: black;">o usar</b> (el valor guardado es el de HORA FIN)</small>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding: 0 0 0 130px;"
                >
                  <interval-times
                    :label="`${$t('booking.scheduleForm.endTime')}:`"
                    v-model="editedItem.TimeEnd"
                  />
                </v-col>
              </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <delete-item
            v-if="editedItem.ID"
            :id="editedItem.ID"
            :onDeleteItem="handleDelete"
            
          />
          <v-btn
            @click="() => onClose(null)"
          >
            {{ $t('common.cancel', locale) }}
          </v-btn>
          <v-btn
            color="primary"
            @click="handleSave"
            :disabled="!editedItem.EventID"
          >
            {{ $t('common.save', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from '@/services/api'
//import utils from '@/services/utils'
import IntervalTimes from './intervalTimes/Index'
import DeleteItem from '@/components/DeleteItem' 
import Duration from './intervalTimes/Duration'
export default {
  components: {
    IntervalTimes,
    DeleteItem,
    Duration,
  },
  props: {
    planID: {
      type: String,
      required: true,
    },
    contentTypeID: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      default: null,
    },
    onClose: {
      type: Function,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    allActivities: {
      type: Array,
      default: null,
    },
    defaultSubcategoryID: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    editedItem: null,

    selectedSubcategory: null,
  }),
  computed: {
  },
  watch: {
    value () {
      this.prepareValue()
    },
    'editedItem.Time' (v) {
      if (!this.editedItem) return
      this.editedItem.TimeEnd = v + (this.editedItem.Duration ? this.editedItem.Duration : 60)
    },
    'editedItem.TimeEnd' (v) {
      const aux = v - this.editedItem.Time
      if (aux !== this.editedItem.Duration) this.editedItem.Duration = aux
    },
    defaultSubcategoryID (v) {
      if (v) this.selectedSubcategory = v
    },
    selectedSubcategory (v) {
      if (!v || !this.editedItem.EventID) return
      const aux = this.allActivities.filter(x => x.ID === this.editedItem.EventID).map(item => item.SubcategoryID).shift()
      if (v !== aux && aux) this.editedItem.EventID = null
    },
  },
  mounted () {
    this.prepareValue()
  },
  methods: {
    prepareValue () {
      this.selectedSubcategory = null
      if (this.defaultSubcategoryID) this.selectedSubcategory = this.defaultSubcategoryID
      else this.selectedSubcategory = this.config.subcategory[0].id

      const aux = this.value ? JSON.parse(JSON.stringify(this.value)) : null
      if (aux) aux.lmu = new Date().toISOString()
      this.editedItem = aux
      this.editedItem.Duration = this.editedItem.TimeEnd - this.editedItem.Time
      this.dialog = this.editedItem ? true : false
    },
    handleChangeDuration (v) {
      this.editedItem.Duration = v
      this.editedItem.TimeEnd = this.editedItem.Time + v
    },
    handleSave () {
      this.editedItem.Common = this.allActivities.filter(x => x.ID === this.editedItem.EventID).map(item => item.Common).shift() ? true : false

      if (this.editedItem.ID) {
        api.updateItem ('dviewcontent', `v1/private/activity-barcelo-01/events/${this.contentTypeID}/${this.planID}/`, this.editedItem.ID, this.editedItem)
          .then(() => {   
            this.onClose(true)
          })
      } else {
        api.addItem ('dviewcontent', `v1/private/activity-barcelo-01/events/${this.contentTypeID}/${this.planID}`, this.editedItem)
          .then(() => {   
            this.onClose(true)
          })
      }
    },
    handleDelete () {
      api.deleteItem ('dviewcontent', `v1/private/activity-barcelo-01/events/${this.contentTypeID}/${this.planID}/`, this.editedItem.ID)
        .then(() => {   
          this.onClose(true)
        })
    },
  },
}
</script>

