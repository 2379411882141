<template>
  <v-layout
    wrap
    class="duration"
  >
    <table>
      <tr>
        <td v-if="label" class="duration-label">
          {{label}}
        </td>
        <td style="width:70px;padding-left: 10px;">
          <v-dialog
            v-model="dialog"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <span style="text-transform: lowercase !important;">{{ duration }} min.</span>
              </v-btn>
            </template>

            <v-card>
              <v-card-text>
                <br/>
                <v-row>
                  <v-col
                    v-for="item of durations"
                    :key="item.id"
                    cols="12"
                    sm="4"
                    style="padding: 5px;"
                  >
                    <v-btn
                      color="primary"
                      @click="handleChange(item.id)"
                      block
                      big
                    >
                      <span style="text-transform: lowercase !important;">{{ item.name }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialog = false"
                >
                  {{ $t('common.cancel', locale)}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </td>
      </tr>
    </table> 
  </v-layout>
</template>

<script>
  export default {
    components: {
    },
    props: {
      label: {
        type: String,
        required: false,
        default: null
      },
      value: {
        type: Number,
        required: false,
        default: 0
      },
      onChange: {
        type: Function,
        required: true,
      },
      locale: {
        type: String,
        default: 'es',
      },
    },
    data: () => ({
      dialog: false,
      duration: null,
    }),
    computed: {
      durations () {
        const result = []
        let aux = 15
        for (let i = 0; i < 12; i++) {
          result.push(
            {
              id: aux,
              name: this.getHoursMinutes(aux)
            })
          aux += 15
        }
        return result
      },
    },
    watch: {
      value: {
        handler (v) {
          if (v) this.prepareDuration(v)
        },
        deep: true
      }
    },
    mounted (){
      this.prepareDuration(this.value ? this.value : 0)
    },
    methods: {
      prepareDuration (v) {
        this.duration = v
      },
      getHoursMinutes (value) {
        let aux = value / 60

        const hours = aux > 0 ? parseInt(aux) : 0
        const minutes = value - (hours * 60)
        let result = ''
        if (hours > 0) result = `${hours}h. `
        if (minutes > 0) result += `${minutes}min.`
        return result
      },
      handleChange(v) {
        if (v) this.duration = v
        this.$emit('input', this.duration)
        this.onChange(this.duration)
        this.dialog = false
      },
    },
  }
</script>
<style scoped>
  .duration {
  }
  .duration-label {
    font-size: 0.875rem;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
    /* font-size: 20px; */
    font-weight: 700;
    text-align: right;
  }
</style>

