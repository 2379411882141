var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.eventType === 'cardOn')?_c('v-card',{key:_vm.data.ID,staticClass:"mx-auto",staticStyle:{"border-radius":"10px","cursor":"pointer !important"},style:({ opacity:  _vm.data.old ? 0.4 : 1}),attrs:{"elevation":3}},[_c('div',{style:({ backgroundColor: _vm.color })},[_c('v-img',{staticStyle:{"width":"100%"},style:({ height: _vm.smallCard ? '116px' : null }),attrs:{"src":_vm.image,"aspect-ratio":"16/9","cover":""}})],1),_c('v-card-title',{style:({ color: _vm.activityColor1 ? _vm.activityColor1 : _vm.color1 }),attrs:{"color":"red"}},[_c('div',{staticClass:"time",style:({ 
        top: _vm.smallCard ? '103px' : ((180) + "px"), 
      })},[_c('v-icon',{staticClass:"time-icon",staticStyle:{"font-size":"0.9rem"},attrs:{"dark":""}},[_vm._v("mdi-clock-time-five-outline")]),(_vm.data.AllTimes)?_c('span',[_vm._v("Todo el día")]):_vm._e(),(!_vm.data.AllTimes)?_c('span',[_vm._v(_vm._s(_vm.time)+" "+_vm._s('-')+" "+_vm._s(_vm.timeEnd))]):_vm._e()],1),_c('b',[(_vm.data.Recurrent)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"black","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-book-sync ")])]}}],null,false,3796701415)},[_c('span',[_vm._v("Actividad recurrente")])]):_vm._e(),_vm._v(" "+_vm._s(_vm.getTranslation(_vm.title, _vm.locale))+_vm._s(_vm.data.HideEvent ? ' [oculto]' : '')+" ")],1)]),_c('v-card-subtitle',[(_vm.detail)?_c('p',{staticStyle:{"margin":"0"}},[_vm._v(" "+_vm._s(_vm.getTranslation(_vm.detail, _vm.locale))+" ")]):_vm._e(),_c('p',{staticStyle:{"margin":"0px 0 0 0"}},[_vm._v(" "+_vm._s(_vm.getTranslation(_vm.subtitle, _vm.locale))+" ")])])],1):_c('v-alert',{staticStyle:{"cursor":"pointer !important","margin":"0"},attrs:{"color":_vm.color,"dark":""}},[_c('div',{staticClass:"text-h6"},[(_vm.data.Recurrent)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"white","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-book-sync ")])]}}],null,false,932646791)},[_c('span',[_vm._v("Actividad recurrente")])]):_vm._e(),_vm._v(" "+_vm._s(_vm.getTranslation(_vm.title, _vm.locale))+_vm._s(_vm.data.HideEvent ? ' [oculto]' : '')+" ")],1),_c('div',[_c('v-icon',{staticClass:"time-icon",staticStyle:{"font-size":"0.9rem"},attrs:{"dark":""}},[_vm._v("mdi-clock-time-five-outline")]),(_vm.data.AllTimes)?_c('span',[_vm._v("Todo el día")]):_vm._e(),(!_vm.data.AllTimes)?_c('span',[_vm._v(_vm._s(_vm.time)+" "+_vm._s('-')+" "+_vm._s(_vm.timeEnd))]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }