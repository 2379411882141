<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          Replicar semana
          <v-icon right>
            mdi-content-copy
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Replicar semana
        </v-card-title>

        <v-card-text>
          <v-btn
            v-for="item of weeks"
            :key="item.pos"
            block
            @click="handleSelected(item.id)"
            color="primary"
            style="margin: 5px;"
          >
            {{ item.name }} ({{item.pos}} semana)
          </v-btn>
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from '@/services/api'
export default {
  props: {
    actualDate: {
      type: String,
      required: true,
    },
    contentTypeID: {
      type: String,
      required: true,
    },
    planID: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    weeks: [],
  }),
  watch: {
    dialog () {
      this.prepareWeek()
    },
  },
  mounted () {
  },
  methods: {
    getMonday (v) {
      let aux = ((v.getDay() === 0 ? 7 : v.getDay()))*-1
      aux++ 
      return new Date(this.addDays(v, aux))
    },
    addDays (v, d) {
      const result = v.setDate(v.getDate() + d)
      return new Date(result)
    },
    formatDate (v) {
      return v.toISOString().split('T').shift()
    },
    copy (v) {
      return new Date(JSON.parse(JSON.stringify(v)))
    },
    prepareWeek () {
      if (!this.dialog) {
        this.weeks = []
        return 
      }
      let ini = this.getMonday(new Date())
      for (let i = 0; i < 12; i++) {
        
        if (i>0) this.weeks.push({ id: this.copy(ini), name: `${this.formatDate(this.copy(ini))} - ${this.formatDate(this.addDays(this.copy(ini), 6))}`, pos: i})
        ini = this.addDays(this.copy(ini), 7)
      }
    },
    async handleSelected (v) {
      try {
        const events = []
        for (let i = 0 ; i < 7; i++) {
          const date = this.formatDate(this.addDays(this.copy(v), i))
          events.push(...await new Promise((resolve, reject) => api.getAllWithoutLimit('dviewcontent', `v1/public/activity-barcelo-01/events/${this.contentTypeID}/${this.planID}/${date}`)
            .then((response) => {
              resolve(response.filter(x => x.Source !== 'romantic'))
            })
            .catch(error => {
              reject(error)
            })))
        }
        if (events.length > 0) {
          alert('ERROR!!!. No se puede replicar la semana en una que ya contenga eventos.')
          return
        }
        let toDate = this.formatDate(this.copy(v))
        const result = await new Promise((resolve, reject) => api.put('dviewcontent', `v1/private/activity-barcelo-01/events/${this.contentTypeID}/${this.planID}/${this.actualDate}/${toDate}/copy`)
          .then((response) => {
            resolve(response.data)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          }))
        // TODO copiar events
        if (result && result.status === 'OK') {
          alert('Eventos copiados correctamente')
          this.dialog = false
        } else {
          alert('ERROR!!!. Ha ocurrido un error al copiar los eventos seleccionados.')
        }
      } catch (error) {
        alert('error')
        console.log(error)
      }
    },
  },
}
</script>
