<template>
  <v-row justify="center" style="padding: 10px 10px;">

    <v-btn
      block
      @click="dialog = true"
      :color="config.filter(x => x.id === editedItem).map(item => item.color).shift()"
      :dark="editedItem ? true : false"
    >
      {{ editedItem ? config.filter(x => x.id === editedItem).map(item => item.name.es).shift() : 'Sel. día temático'}}
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-if="config"
              cols="12"
              sm="12"
              md="12"
              style="padding: 10px 20px;"
            >
              <v-container
                class="px-0"
                fluid
                v-if="selectableWeekday"
              >
                <v-radio-group v-model="editedItem">
                  <v-radio
                    v-for="n in selectableWeekday"
                    :key="n.id"
                    :label="n.name && n.name.es ? n.name.es : 'Sin definir'"
                    :value="n.id && n.id !== 'null' ? n.id : null"
                  />
                </v-radio-group>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            dark
            @click="handleUnselect"
          >
            Deseleccionar
          </v-btn>
          <v-btn
            @click="() => handleClose(null)"
          >
            {{ $t('common.cancel', locale) }}
          </v-btn>
          <v-btn
            color="primary"
            @click="handleSave"
          >
            {{ $t('common.save', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from '@/services/api'
//import utils from '@/services/utils'
export default {
  components: {
  },
  props: {
    planID: {
      type: String,
      required: true,
    },
    contentTypeID: {
      type: String,
      required: true,
    },
    day: {
      type: Object,
      requried: true,
    },
    weekdayConfig: {
      type: Object,
      required: true,
    },
    config: {
      type: Array,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    }
  },
  data: () => ({
    dialog: false,
    selectableWeekday: null,
    editedItem: null,
  }),
  computed: {
  },
  watch: {
    dialog () {
      this.prepareSelectableWeekday()
    },
    'day.weekdayID' () {
      this.prepareEditedItem()
    },
    weekdayConfig () {
      this.prepareEditedItem()
    },
    config () {
      this.prepareEditedItem()
    },
    contentTypeID () {
      this.prepareEditedItem()
    },
    planID () {
      this.prepareEditedItem()
    },
  },
  mounted () {
    this.prepareEditedItem()
  },
  methods: {
    prepareSelectableWeekday () {
      const aux = this.day.weekdayID
      let nn = Object.keys(this.weekdayConfig).map(k => this.weekdayConfig[k])
      nn = nn.filter(x => x !== aux)
      this.selectableWeekday = this.config.filter(x => nn.indexOf(x.id) < 0 && x.id !== 'null')
    },
    prepareEditedItem () {
      this.editedItem = this.day && this.day.weekdayID && this.day.weekdayID !== 'null' ? this.day.weekdayID : null
    },
    handleClose (v) {
      if (v) {
        this.onClose(this.day.date, this.editedItem && this.editedItem !== 'null' ? this.editedItem : null)
      }
      this.dialog = false
    },
    handleUnselect () {
      this.editedItem = null
      this.handleSave()
    },
    handleSave () {
      const body = {
        PlanID: this.planID,
        ContentTypeID: this.contentTypeID,
        Date: this.day.date,
        WeekdayID: this.editedItem && this.editedItem !== 'null' ? this.editedItem : null
      }
      api.addItem ('dviewcontent', `v1/private/activity-barcelo-01/weekday/${this.contentTypeID}/${this.planID}`, body)
        .then(() => {   
          this.handleClose(true)
        })
    },
  },
}
</script>

